<template>
  <div class="side-box" v-if="!isCollapse" @click="closeModal">
    <!-- 菜单栏 -->
    <div class="menu-list-container">
      <div class="flex items-center cursor-pointer side-title text-text-block leading-6 text-lg font-medium">
        <img :src="hamburgerButton" alt="" class="mr-4 h-6" />
        <div>{{ toolTitle }}</div>
      </div>
      <div class="pr-1">
        <div class="route-list-container pl-11 " :style="{ height: listContainerHeight + 'px' }">
          <div v-for="collections in toolsConfig" :key="collections.collection" class="py-9">
            <h1 class="text-2xl">{{ collections.collection }}</h1>
            <p class="route-item pt-6 font-normal"
              v-for="item in collections.children.filter(item => !['/face-swapper', '/video-inpainting'].includes(item.path))"
              :key="item.name" :class="{ 'active-path-style': currentPath === item.path }" @click="handleToPage(item)">
              {{ item.title }}
              <img class="pro-icon" width="16" height="16" v-if="item.isPro" :src="proIcon" />
            </p>
          </div>
        </div>
      </div>
      <div class="menu-list-footer" ref="footerRef">
        <div style="width: 100%; border-top: 1px solid #D0D0D0">
          <div class="footer-text">
            <template v-if="planName === 'Free'">
              <p style="margin: 8px 0">Plan: Free</p>
            </template>
            <template v-else>
              <!-- #646A73 -->
              <div style="color: #646A73">
                <span>Plan: {{ planName }}</span>
                <div class="info-icon-container">
                  <img :src="infoIcon" width="18" height="18" class="info-icon" />
                  <div class="plan-info-container">
                    <div class="plan-info-bg"></div>
                    <ul class="plan-info">
                      <li v-for="info in planInfo" :key="info">{{ info }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <p v-if="!/Free|AppSumo/.test(planName)">Renews on:
                {{ dayjs(subscriptionInfo.dueTime).format('MM / DD / YYYY') }}</p>
            </template>
          </div>
          <div class="footer-btn" @click="handleToPage({ path: '/premium' })">
            {{ planName === 'Free' ? 'Upgrade' : 'Manage Subscription' }}
          </div>
        </div>
      </div>
    </div>
    <!-- 导航栏按钮 -->


  </div>
</template>
<script setup>
import { ref, watchEffect, defineExpose, onMounted, onUnmounted, watch, nextTick } from "vue";
import { getToolTitleByPath, toolsConfig } from '@/components/models/config.js';
import hamburgerButton from '@/assets/hamburger-button.svg';
import proIcon from '@/assets/common-icons/icon_vip.svg';
import infoIcon from '@/assets/common-icons/icon_tip.svg';
import dayjs from 'dayjs';
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useSideStore } from '@/store'
import { useSubscriptionInfo } from '@/store/user.js'
import { eventTracking } from '@/utils/eventTracking.js'

const subscriptionInfo = useSubscriptionInfo();
const userStore = useSideStore();

const toolTitle = ref("");
const { isCollapse } = storeToRefs(userStore);
const route = useRoute();
const router = useRouter();
const currentPath = ref(null);
const listContainerHeight = ref(0);
const planName = ref('Free');
const planInfo = ref([]);
const footerRef = ref(null);

const updateScreenHeight = () => {
  const screenHeight = window.innerHeight;

  if (footerRef.value !== null) {
    listContainerHeight.value = screenHeight - 60 - footerRef.value.offsetHeight;
  }
}

watchEffect(() => {
  switch (subscriptionInfo.packageCode) {
    case 201101:
    case 201102:
      planName.value = 'Pro';
      break;
    case 201201:
    case 201202:
      planName.value = 'Ultra';
      break;
    case 200801:
      if (subscriptionInfo.paymentPlatform == 'aliPay') {
        // 国内平台显示不一样
        planName.value = 'Special Deal';//pro
      } else {
        planName.value = 'AppSumo Tier1';//pro
      }
      break;
    case 200901:
      if (subscriptionInfo.paymentPlatform == 'aliPay') {
        // 国内平台显示不一样
        planName.value = 'Special Deal';//pro
      } else {
        planName.value = 'AppSumo Tier2';//Ultra
      }
      break;
    case 200701:
      planName.value = 'Free';
      break;
    default:
      planName.value = 'Free';
      break;
  }

  switch (subscriptionInfo.packageCode) {
    case 201101:
    case 201102:
    case 200801:
      planInfo.value = [
        'Unlimited access to all Pro features',
        `Batch processing balance: ${subscriptionInfo.getUserResource('BOOLTOOL_BATCH_PROCESS').num}`,
      ];
      break;
    case 201201:
    case 201202:
    case 200901:
      planInfo.value = [
        'Unlimited access to all Ultra features',
        `Video credit balance: ${subscriptionInfo.getUserResource('BOOLTOOL_VIDEO_DURATION').num} seconds`,
      ];
      break;
    case 200701:
      planInfo.value = [];
      break;
    default:
      planInfo.value = [];
      break;
  }
});

watch([subscriptionInfo, isCollapse], async () => {
  await nextTick()
  updateScreenHeight()

});

onMounted(() => {
  window.addEventListener('resize', updateScreenHeight);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateScreenHeight);
})

watchEffect(
  () => {
    toolTitle.value = getToolTitleByPath(route.path)
    currentPath.value = route.path
    if (route.path == "/premium") {
      toolTitle.value = "Upgrade Plan"
    } else if (route.path == "/refund") {
      toolTitle.value = "Request Refund"
    }
  }
)
const handleToPage = (item) => {
  eventTracking("booltool_page_view", { tool_name: item.trackName })

  router.push({ path: item.path });
};

const closeModal = () => {
  userStore.isCollapse = true;
};

defineExpose({
  closeModal,
})

</script>
<style lang="less" scoped>
.side-box {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 9999;

}

.menu-list-container {
  width: 420px;
  height: 100%;
  background: #FFFFFF;
  opacity: 1;
  // box-shadow: 0px 0px 62px rgba(0, 0, 0, 0.07);
  animation: men-list-animation 0.4s 1;
}

.menu-list-footer {
  width: 400px;
  padding: 0 36px 24px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #646A73;

  .footer-text {
    display: flex;
    flex-direction: column;
    padding: 22px 0;
    align-items: flex-start;
    justify-content: space-around;

    &>p {
      margin-bottom: 8px;
      margin-top: 8px;
    }

    .refund {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: #875EFF;
      }
    }
  }

  .footer-btn {
    background: #060606;
    height: 48px;
    border-radius: 7px;
    font-weight: 400;
    font-size: 16px;
    line-height: 48px;
    text-align: center;
    width: 100%;
    color: #FFE68E;
    cursor: pointer;

    &:hover {
      background: #212121;
    }
  }
}

.side-title {
  padding-left: 36px;
  height: 60px;
  border-bottom: 1px solid #e9e9e9;
}

@keyframes men-list-animation {
  0% {
    left: -400px;
  }

  95% {
    left: 15px;
  }

  100% {
    left: 0px;
  }
}

.close-icon-box {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0 0px 43.25px 0;
  border-radius: 4px;

  &:hover {
    background: #ededed;
  }
}

.route-list-container {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  height: 740px;
  color: #000000;
  text-align: left;
  overflow-y: scroll;
}

.route-item {
  cursor: pointer;
  width: 100%;

  &:hover {
    color: #875EFF;
  }
}

.active-path-style {
  color: #875eff;
  width: 100%;
}

.opa-fade-enter-active,
.opa-fade-leave-active {
  transition: all 0.1s ease;
}

.opa-fade-enter-from,
.opa-fade-leave-to {
  left: -400px;
}

.sider-policy-container {
  font-family: 'Inter', Arial;
  color: #8f959e;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  text-align: left;
  position: absolute;
  bottom: 42px;
}

.sider-policy {
  cursor: pointer;
  margin-top: 16px;
  width: 110px;
}

.image-show-tips {
  position: relative;
  left: -18px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.image-show-tips-arrow {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom: 6px solid #000000;
}

.image-show-tips-box {
  color: #ffffff;
  height: 36px;
  background: #000000;
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 20px;
}

.sider-policy-container {
  width: 110px;
}



::-webkit-scrollbar-track {
  background-color: none;
  border-radius: 3.5px;
}

/*定义滚动条高宽及背景*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/*定义滚动条*/
::-webkit-scrollbar-thumb {
  height: 7px;
  background: rgba(31, 35, 41, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-corner {
  background-color: #EDEDED;
}

.pro-icon {
  display: inline-block;
  margin-left: 8px;
}

.info-icon-container {
  display: inline-block;
  position: relative;
  margin-left: 8px;
  vertical-align: bottom;

  &:hover>.plan-info-container {
    opacity: 1;
  }
}

.plan-info-container {
  position: absolute;
  top: -14px;
  left: -36px;
  width: max-content;
  padding: 20px 24px 24px;
  transform: translateY(-100%);
  color: white;
  text-align: left;
  opacity: 0;
  transition: opacity .3s;
  pointer-events: none;

  &>.plan-info {
    padding-left: 20px;
    list-style-type: disc;
    line-height: 22px;
  }

}

.info-icon {
  cursor: pointer;
}

.plan-info-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  border-radius: 6px;
  opacity: .9;
  z-index: -1;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 35px;
    width: 20px;
    height: 20px;
    clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
    background-color: black;
  }
}
</style>
