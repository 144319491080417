<template>
  <ToolkitHeader v-if="route.meta.showHeader === 'tools'"></ToolkitHeader>
  <HomeHeaderInPC v-else-if="isPC && route.meta.showHeader === 'home'"/>
  <DefaultHeaderInPE v-else-if="!isPC"/>
</template>

<script setup>
import { computed } from "vue";
import { useWindowSize } from "@vueuse/core";
import { useRoute } from "vue-router";
import ToolkitHeader from "./components/toolkitHeader.vue";
import HomeHeaderInPC from './components/HomeHeaderInPC.vue';
import DefaultHeaderInPE from './components/DefaultHeaderInPE.vue';
import { tailwindConfig as tailwindSharedConfig } from '@/shared.config.js';

const route = useRoute();
const { width: innerWidth } = useWindowSize();
const isPC = computed(() => tailwindSharedConfig.screens.m.max < innerWidth.value);
</script>