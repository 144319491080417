<template>
  <div :class="['x-collapse', props.open ? 'open' : '', props.collapseClass]" ref="collapseRef">
    <div class="x-summary" @click="handleCollapse">
      <slot name="summary"></slot>
    </div>
    <div class="x-container">
      <div class="x-content" ref="contentRef" v-bind="$attrs">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false
}
</script>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const collapseRef = ref(null);
const contentRef = ref(null);
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  handleToggle: {
    type: Function,
    default: () => {},
  },
  handleResize: {
    type: Function,
    default: () => {},
  },
  collapseClass: {
    type: String,
    default: ''
  }
});

const resizeObserver = new ResizeObserver(entries => {
  if (!collapseRef.value.classList.contains('open')) {
    return;
  }

  for (const entry of entries) {
    if (entry.target === contentRef.value) {
      props.handleResize(entry, collapseRef.value);
      break;
    }
  }
});

function handleCollapse() {
  const el = collapseRef.value;
  const isOpen = el.classList.contains('open');

  el.classList.toggle('open');
  props.handleToggle(!isOpen, contentRef.value, el);
}

onMounted(() => {
  resizeObserver.observe(contentRef.value);
});

onBeforeUnmount(() => {
  resizeObserver.unobserve(contentRef.value);
});
</script>

<style scoped>
.x-container {
  overflow-y: hidden;
}
</style>