<template>
  <header :class="[
    'navigation-view',
    viewStore.header.disabled ? 'disabled' : ''
  ]" :style="{ minWidth: viewStore.minViewWidth }">
    <div :class="[
      'navigation-header',
      isActive ? 'active' : '',
      theme,
    ]">
      <div class="header-container">
        <img :src="boolvLogo" width="140" alt="boolv" @click="toHome"/>
        <div class="expend-icon" @click="handleClick">
          <i></i>
        </div>
      </div>
    </div>
    <div class="navigation-content">
      <HeaderContent :minWidth="viewStore.minViewWidth"/>
    </div>
  </header>
</template>

<script setup>
import {
  ref,
  watch,
  provide,
  computed,
  getCurrentInstance,
  onBeforeUnmount,
watchEffect,
} from 'vue';
import { useViewStore } from '@/store';
import { useRoute, useRouter } from 'vue-router';
import { useWindowScroll } from '@vueuse/core';
import boolvLogo from '@/assets/home/logo.svg';
import HeaderContent from './HeaderContentInPE.vue';

const { y: scrollY } = useWindowScroll();
const isActive = ref(false);
const route = useRoute();
const router = useRouter();
const instance = getCurrentInstance();
const theme = computed(() => viewStore.header.theme === 'normal' ? (scrollY.value === 0 ? 'light' : 'dark') : viewStore.header.theme);
const viewStore = useViewStore();

provide('isNavigationActive', isActive);
watch(() => isActive.value, (value) => {
  if (value) {
    document.body.setAttribute(instance.subTree.scopeId, '');
  } else {
    document.body.removeAttribute(instance.subTree.scopeId);
  }
});

function toHome() {
  route.path !== '/home' && router.push('/home');
  isActive.value = false;
}

function handleClick() {
  isActive.value = !isActive.value;
}

onBeforeUnmount(() => {
  document.body.removeAttribute(instance.subTree.scopeId);
});
</script>

<style scoped>
body {
  overflow-y: hidden;
}

.navigation-view {
  position: fixed;
  inset-inline: 0;
  z-index: 1002;
  opacity: 1;
  transition: opacity .2s;
}

.navigation-view.disabled {
  pointer-events: none;
  opacity: 0;
}

.navigation-header {
  background-color: #000;
  transition: background-color .2s;
}


.navigation-header.light {
  background-color: transparent;
}

.navigation-header.active {
  transition: none;
}

.navigation-header::before {
  content: '';
  position: absolute;
  inset: 0;
  right: 100%;
  background-color: #000;
  transition: right .2s;
  pointer-events: none;
}

.navigation-header.active::before {
  right: 0;
}

.header-container {
  @apply px-sv;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
}

.header-container > img {
  display: inline-block;
  cursor: pointer;
}

.navigation-content {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 100%;
  background-color: #000;
  z-index: 9998;
  transition: right .2s;
}

.navigation-header.active + .navigation-content {
  right: 0;
  opacity: 1;
}

.expend-icon {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.expend-icon > i, .expend-icon::before, .expend-icon::after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 2.5px;
  margin-block: auto;
  border-radius: 2px;
  background-color: #fff;
}

.expend-icon > i {
  top: 0;
  bottom: 0;
}

.expend-icon::before {
  top: 3px;
  transform-origin: 12.5px 12.5px;
  transition: transform .2s;
}

.expend-icon::after {
  bottom: 3px;
  transform-origin: 4px -4px;
  transition: transform .2s;
}

.navigation-header.active .expend-icon > i {
  height: 0;
}

.navigation-header.active .expend-icon::before {
  transform: rotate(45deg) translateY(9px);
}

.navigation-header.active .expend-icon::after {
  transform: rotate(-45deg);
}
</style>