<template>
  <div class="content-view" :style="{ minWidth: props.minWidth }">
    <div class="content-container">
      <ul class="panel-list">
        <li v-if="isLogin" class="user-info" @click="showDrawer = true">
          <img :src="userAvatar" height="40" style="display: inline-block"/>
          <span class="user-name">{{ user.userName }}</span>
          <span class="user-plan" v-if="planName !== 'Free'">{{ planName }}</span>
        </li>
        <li v-for="panel in panelList" :key="panel.collection">
          <Collapse
            :handleToggle="handleCollapse"
            :collapseClass="'menu-item'"
            :handleResize="handleCollapseResize"
          >
            <template #summary>
              <span class="menu-title">{{ panel.collection }}</span>
            </template>
            <ul class="item-list">
              <li
                v-for="item in panel.children"
                :key="item.title"
                @click="handleRouter(item)"
              >
                <span>{{ item.title }}</span>
              </li>
            </ul>
          </Collapse>
        </li>
        <li @click="handleClickLink({target: '_self', link: '/homePremium'})">
          <span class="menu-title">Pricing</span>
        </li>
      </ul>
    </div>

    <div v-if="!isLogin" class="bottom-part">
      <ul class="button-group">
        <li>
          <button @click="handleClickLink({target: '_self', link: '/login'})">
            Log in
          </button>
        </li>
        <li>
          <button
            class="sign-button"
            @click="handleClickLink({target: '_self', link: '/signup'})"
          >Sign up</button>
        </li>
      </ul>
    </div>

    <a-drawer
      placement="bottom"
      :closable="false"
      :visible="showDrawer"
      :zIndex="1003"
      height="fit-content"
      :maskStyle="{background: 'rgba(0,0,0,.6)'}"
      :bodyStyle="{paddingBlock: '4px 56px'}"
      @close="showDrawer = false"
    >
      <ul class="action-list">
        <li @click="handleResetPwd">Reset password</li>
        <li @click="handleLogout">Sign out</li>
      </ul>
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, inject, watchEffect } from 'vue';
import Collapse from '@/components/collapse.vue';
import { eventTracking } from '@/utils/eventTracking.js';
import { toolsConfig } from "@/components/models/config.js";
import { useUserStore, useSubscriptionInfo } from '@/store'
import { solutions } from '../data.config.js';
import { useRouter } from 'vue-router';
import { isLogin } from '@/utils/auth';
import userAvatar from "@/assets/user_default_avatar.svg";
import { showModal, currentToolName } from '@/views/homePage/viewModel';

let expandedNode = null;
const router = useRouter();
const user = useUserStore();
const planName = ref('');
const showDrawer = ref(false);
const subscriptionInfo = useSubscriptionInfo();
const isNavigationActive = inject('isNavigationActive');
const panelList = [...toolsConfig, { collection: 'Solutions', target: '_self', children: solutions }];
const props = defineProps({
  minWidth: {
    type: String,
    default: ''
  }
});


function handleRouter(item) {
  eventTracking("booltool_homepage_click", { tool_name: item.trackName, trigger_location: 'footer' });

  // if (["/face-swapper", "/video-background-remove", "/video-inpainting"].includes(item.path)) {
  //   currentToolName.value = item.trackName;
  //   showModal.value = true;
  //   isNavigationActive.value = false;
  //   return ;
  // } 
  window.open(router.resolve({ path: item.path }).href, '_blank');
  
}

function handleClickLink({ link, target='_blank' }) {
  if (target === '_self' || !/^https?:/.test(link)) {
    router.push({ path: link });
    isNavigationActive.value = false;
  } else {
    window.open(link, target);
  }
}

//重置密码
const handleResetPwd = () => {
  showDrawer.value = false;
  isNavigationActive.value = false;
  router.push({ path: '/resetPwd', query: {step: 1, type: "reset" }});
}

//退出登录
const handleLogout = async () => {
  showDrawer.value = false;
  isNavigationActive.value = false;
  router.push('/login');
  await user.logout();
}

function handleCollapse(isOpen, contentRef) {
  contentRef.parentElement.style.height = isOpen ? `${contentRef.clientHeight}px` : 0;

  if (expandedNode !== null && expandedNode !== contentRef) {
    expandedNode.parentElement.style.height = '0';
    expandedNode.closest('.x-collapse').classList.remove('open');
  }

  expandedNode = contentRef;
}

function handleCollapseResize({ target }) {
  target.parentElement.style.height = `${target.clientHeight}px`;
}

watchEffect(() => {
  switch (subscriptionInfo.packageCode) {
    case 201101:
    case 200801:
      planName.value = 'Pro';
      break;
    case 201201:
    case 200901:
    case 200701:
      planName.value = 'Ultra';
      break;
    default:
      planName.value = 'Free';
      break;
  }
});
</script>

<style scoped>
.content-view {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  right: 0;
  color: #000;
  text-align: left;
}

.content-container {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-container::-webkit-scrollbar {
  position: absolute;
  width: 0;
}

.content-container::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 2px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-name {
  margin-left: 8px;
  font-size: 16px;
  color: #fff;
}

.user-plan {
  padding: 2px 6px;
  margin-left: 8px;
  font-size: 12px;
  color: #FFE68E;
  background-color: #060606;
  border-radius: 2px;
}

.action-list {
  font-family: 'Inter', Arial;
  font-size: 16px;
}

.action-list > li {
  padding-block: 20px;
  border-bottom: 1px solid #EDEDED;
}

.panel-list {
  @apply px-sv;

  width: 100%;
  min-width: 100vw;
}

.panel-list > li {
  padding-block: 30px;
  border-bottom: 1px solid #333;
}

.menu-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
}

.menu-item:deep > .x-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
}

.menu-item:deep > .x-summary::after, .user-info::after {
  content: '';
  display: block;
  width: 25px;
  height: 25px;
  background: url('../assets/icon_arrow.svg') no-repeat center/50%;
  transition: transform .2s;
  cursor: pointer;
}

.user-info::after {
  margin-inline: auto 0;
  transform: rotate(-90deg);
}

.menu-item.open:deep > .x-summary::after {
  transform: rotate(180deg);
}

.menu-item:deep > .x-container {
  height: 0;
  transition: height .2s;
}

.item-list > li {
  padding-top: 24px;
  font-size: 16px;
  color: #D5D6D7;
}

.item-list > li > span:hover {
  color: #9774FF;
  cursor: pointer;
}

.bottom-part {
  position: relative;
  padding-block: 16px 45px;
}

.button-group {
  text-align: center;
  font-size: 16px;
  color: #fff;
}

.sign-button {
  width: 230px;
  height: 56px;
  margin-top: 24px;
  line-height: 56px;
  border-radius: 28px;
  border: 1px solid #535558;
}
</style>