//title -> trackname

export const titleToTrackNameJson = {
  "Remove & Change Portrait Background": "bg_remover",
  "Remove & Change Object Background": "object_bg_remover",
  "Background Blur": "blur",
  "Object Eraser": "eraser",
  "Image Enhancer": "enhancer",
  "Photo Filters and Effects": "filter",
  "Image Resizer": "resizer",
  "Image Compressor": "compressor",
  "Photo Animation Effects": "animation",
  "AI Paraphraser": "paraphraser",
  "Sugar Comments": "sugar_comment",
  "Photo Storytelling": "storytelling",
  "Video Enhancer": "video_enhancer",
};

export const solutions = [
  {
    title: "Background Remover & Changer",
    path: "/solution/background-remover",
    name: "Background Remover Solution",
    trackName: "background_remover_solution",
  },
  {
    title: "Object Eraser",
    path: "/solution/object-eraser",
    name: "Object Eraser Solution",
    trackName: "object_eraser_solution",
  },
  {
    title: "Image Enhancer",
    path: "/solution/image-enhancer",
    name: "Image Enhancer Solution",
    trackName: "image_enhancer_solution",
  },
  {
    title: "Background Blur",
    path: "/solution/background-blur",
    name: "Background Blur Solution",
    trackName: "background_blur_solution",
  },
  {
    title: "Video Enhancer",
    path: "/solution/video-enhancer",
    name: "Video Enhancer Solution",
    trackName: "video_enhancer_solution",
  },
  {
    title: "AI Paraphraser",
    path: "/solution/AI-paraphraser",
    name: "AI Paraphraser Solution",
    trackName: "ai_paraphraser_solution",
  },
  // {
  //   title: "Photo Storytelling",
  //   path: "/solution/photo-storytelling",
  //   name: "Photo Storytelling Solution",
  //   trackName: "photo_storytelling_solution",
  // },
];