<template>
  <div class="notification-menu-item">
    <div class="notification-menu-item-content">
      <!-- 视频高清化 -->
      <div class="img-menu-item-box" v-if="type === 'image'">
        <!-- 信息部分 -->
        <div class="img-menu-item-content">
          <div class="img-menu-item-content-left">
            <p class="title">{{ props.text }} </p>
            <p class="time">{{ conversionUtcDate(props.time, 'local') }}</p>
          </div>
          <div class="img-menu-item-content-right">
            <div class="mask" v-if="props.disabled">
              <img :src="loading_icon" class="loading" alt="">
            </div>
            <video :src="props.imgUrl"></video>
          </div>
        </div>
        <!-- 按钮部分 -->
        <div class="flex flex-col items-end">
          <custom-btn activeColor="#F2EDFF" :customStyle="customStyle" :disabledStyle="disabledStyle"
            :disabled="props.disabled" @click="handleDownload(props.downloadUrl)">Download
          </custom-btn>
        </div>
      </div>
      <!-- 文字类型提醒 -->
      <div class="text-menu-item-box" v-if="type === 'text'">
        <div class="text-menu-item-content">
          <p class="title">{{ props.text }}</p>
          <p class="time">{{ conversionUtcDate(props.time, 'local') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
} from "vue";
import { eventTracking } from "@/utils/eventTracking.js"
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import { conversionUtcDate } from "@/utils/conversionTime";
import { downloadVideoFromUrl } from "@/utils";
import loading_icon from "@/assets/notification_loading.svg";
//TODO定义消息类型、时间、图片url
const props = defineProps({
  type: {
    type: String,
    default: "text",
  },
  //TODO可能需要改成Number传过来的参数是时间戳
  time: {
    type: String,
    default: "12:15",
  },
  imgUrl: {
    type: String,
    default: "unknown.com",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: '11111',
  },
  downloadUrl: {
    type: String,
    default: "unknown.com",
  },
});
const handleDownload = (src) => {
  eventTracking('booltool_notification_click', { click: 'download', video_link: src });
  downloadVideoFromUrl(src);
}
const customStyle = {
  width: '103px',
  height: '36px',
  background: 'transparent',
  'border-radius': '200px',
  color: '#875EFF',
  border: '1px solid #875EFF'
};
const disabledStyle = {
  width: '103px',
  height: '36px',
  background: 'transparent',
  'border-radius': '200px',
  color: '#C0C0C0',
  border: '1px solid #D0D0D0'
}
</script>

<style lang="less" scoped>
.notification-menu-item {
  display: flex;
  justify-content: center;
  margin: 0 23px;

  .notification-menu-item-content {
    width: 100%;
    padding: 23px 0;
    border-bottom: 1px solid #E9E9E9;

    .img-menu-item-box {
      width: 100%;

      .img-menu-item-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 17px;

        .img-menu-item-content-left {
          display: block;
          text-align: start;
          width: 239px;
        }

        .img-menu-item-content-right {
          position: relative;
          display: flex;
          justify-content: center;
          width: 103px;
          height: 68px;

          .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .loading {
              animation: rotate 1s linear infinite;
              width: 18px;
            }

            @keyframes rotate {
              0% {
                transform: rotate(0deg);
              }

              100% {
                transform: rotate(360deg);
              }
            }
          }

          .example {

            border-radius: 4px;
            object-fit: contain;
          }
        }
      }
    }

    .text-menu-item-box {
      width: 100%;

      .text-menu-item-content {
        width: 100%;
        text-align: start;
      }
    }

  }
}

.title {

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #060606;
  margin-bottom: 17px;
}

.time {

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8F959E;
}
</style>