<template>
  <header class="header-container flex items-center  text-white">
    <div class=" heraComp_header-bg"
      v-if="dropdownShow || y > 54 || route.name !== 'home' && route.name !== 'homePremium'">
    </div>
    <div class="heraComp_header-wrapper">
      <div class="heraComp_header-navGroup">
        <div class="logo-container " @click="handleHome">
          <img :src="logo" />
        </div>
        <div class="heraComp_header-navigation">
          <nav class="heraComp_header-menus">
            <div class="heraComp_header-menuItem" v-for="(name, i) in menus" :key="name">
              <div class="heraComp_header-dropdown heraComp_header-dropdown_active">
                <div class="heraComp_header-itemLink" @mouseenter.self="handleMouseEnter(i, $event)">
                  <span class="heraComp_header-itemLinkText">
                    {{ name }}
                    <img :src="header_arrow_down" class="heraComp_header-arrowIcon" />
                  </span>
                </div>

                <div v-if="dropdownShow" class="heraComp_header-dropdownWrapper heraComp_header-dropdownWrapper_active"
                  :style="{ paddingLeft: `${subMenuOffset | 0}px` }" @mouseleave="handleMouseLeave">
                  <ul class="headerDropdown" v-if="activeIndex === 0">
                    <li v-for="item in toolsConfig[0].children" :key="item.path" class="headerDropdown-li"
                      @click="handleToolsClick(item)">{{
                        item.title
                      }}</li>
                  </ul>
                  <ul class="headerDropdown" v-if="activeIndex === 1">
                    <li v-for="item in toolsConfig[1].children" :key="item.path" @click="handleToolsClick(item)"
                      class="headerDropdown-li">{{
                        item.title
                      }}</li>
                  </ul>
                  <ul class="headerDropdown" v-if="activeIndex === 2">
                    <li v-for="item in toolsConfig[2].children" :key="item.path" @click="handleToolsClick(item)"
                      class="headerDropdown-li">{{
                        item.title
                      }}</li>
                  </ul>
                  <ul class="headerDropdown" v-if="activeIndex === 3">
                    <li v-for="item in solutions" :key="item.path" @click="handleToolsClick(item)"
                      class="headerDropdown-li">{{
                        item.title
                      }}</li>
                  </ul>
                </div>
              </div>

            </div>
            <div class="heraComp_header-price" @click="handleToPrice" @mouseenter="handleMouseEnterPricing">Pricing
            </div>
          </nav>
        </div>
      </div>
      <div class="heraComp_header-navGroup">
        <div class="flex-right flex">
          <div class="user-container" v-if="isLogin">
            <user-avatar></user-avatar>
          </div>
          <div class="user-container flex" v-else>
            <button class="user-item pl-10" @click="handleLoginClick">Log in</button>
            <sign class="user-item ml-10"></sign>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script setup>
import { ref } from 'vue'
import logo from '@/assets/home/logo.svg'
import countdownBanner from './countdown-banner.vue'
import header_arrow_down from '@/assets/home/header_arrow_down.svg'
import sign from './sign.vue'
import userAvatar from './user-avatar.vue'
import { eventTracking } from '@/utils/eventTracking.js'
import { toolsConfig } from "@/components/models/config.js";
import { useRoute, useRouter } from "vue-router";
import { useWindowScroll } from '@vueuse/core'
import { isLogin } from '@/utils/auth.js'
import { solutions } from '../data.config.js'
import { showModal, currentToolName } from '@/views/homePage/viewModel'

const router = useRouter();
const route = useRoute()
const activeIndex = ref(-1);
const dropdownShow = ref(false)
const subMenuOffset = ref(0)
const menus = [...toolsConfig.map(({ collection }) => collection), 'Solutions'];
const handleLoginClick = () => {
  router.push('/login')
}

const handleToPrice = () => {
  router.push('/homePremium')
}

const handleHome = () => {
  router.push('/home')
}

const { y } = useWindowScroll()

const handleToolsClick = (item) => {
  dropdownShow.value = false
  eventTracking("booltool_homepage_click", { tool_name: item.trackName, trigger_location: 'header' })
  // if (["/video-background-remove"].includes(item.path)) {
  //   currentToolName.value = item.trackName;
  //   showModal.value = true;
  //   return;
  // }

  if (item.path.startsWith('/solution/')) {
    router.push(item.path);
    return;
  }

  const url = router.resolve({ path: item.path }).href;
  window.open(url, '_blank')

}
const handleMouseEnter = (index, e) => {
  subMenuOffset.value = e.target.getBoundingClientRect().left;
  activeIndex.value = index;
  dropdownShow.value = true;
}
const handleMouseEnterPricing = () => {
  dropdownShow.value = false;
}
const handleMouseLeave = () => {
  dropdownShow.value = false;

}
</script>

<style lang="less" scoped>
.header-container {
  position: fixed;
  width: 100%;
  height: 60px;
  padding: 0 40px;
  z-index: 1002;
}

.heraComp_header-bg {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #000000;
}

.heraComp_header-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  justify-content: space-between;
  width: 100%;
  position: relative;
}

.heraComp_header-navGroup {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
}

.heraComp_header-navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 60px;
  margin-left: 42px;
}

.heraComp_header-menus {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.heraComp_header-menuItem {
  cursor: pointer;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  transition: background-color .5s ease;
}

.logo-container {
  cursor: pointer;
}

.heraComp_header-dropdown {
  height: 100%;
  position: relative;
}

.heraComp_header-itemLink {
  align-items: center;
  background: none;
  border: 0;
  border-radius: 0;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 100%;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 20px;
  position: relative;
  text-align: center;
  transition: color .5s ease;
  word-break: break-all;
}

.heraComp_header-itemLink:before {
  background: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);
  content: "";
  display: block;
  height: 2px;
  opacity: 0;
  position: absolute;
  top: 44px;
  transition: opacity .2s ease-in-out;
  width: 100%;
}



.heraComp_header-itemLinkText {
  display: flex;

  img {
    margin-left: 5px;
  }
}

.heraComp_header-dropdownWrapper {
  background: #000000;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  left: 50%;
  overflow: auto;
  overflow-x: hidden;
  position: fixed;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  z-index: 8;
}

.headerDropdown {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-bottom: 28px;
}

.headerDropdown-li {
  line-height: 17px;
  margin-bottom: 14px;

  &:hover {
    color: #9774FF;
  }
}

.heraComp_header-price {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 0px 18px;
  cursor: pointer;
}

.heraComp_header-arrowIcon {
  width: 12px;
  display: inline-block;
  font-size: 10px;
  margin-left: 12px;

  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

.heraComp_header-itemLink:hover {
  .heraComp_header-arrowIcon {
    transform: rotate(180deg)
  }
}

.heraComp_header-itemLink:hover:before {
  opacity: 1;
}

.heraComp_header-price:hover:before {
  opacity: 1;
}

.heraComp_header-price:before {}


.nav-container {
  color: #ffff;
}
</style>
