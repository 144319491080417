/* eslint-disable consistent-return */

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
export function conversionUtcTime(date, type) {
  if (type === "local") {
    // 传入local则将UTC时间转换为本地时间
    return dayjs.utc(date).local().format("YYYY.MM.DD_HH:mm");
  }
  if (type === "UTC") {
    // 传入UTC则将时间转换为UTC时间
    return dayjs(date).utc().format();
  }
}

export function conversionUtcDate(UTCDateString, type) {
  if (!UTCDateString) {
    return "-";
  }

  let utcTime;
  if (type === "local") {
    // 传入local则将UTC时间转换为本地时间
    // 判断今天还是昨天
    let dayDiff = 3;
    const today = dayjs();
    const yesterday = dayjs().subtract(1, "day");
    if (dayjs(UTCDateString).isSame(today, "day")) {
      dayDiff = 1;
    } else if (dayjs(UTCDateString).isSame(yesterday, "day")) {
      dayDiff = 2;
    }
    const date = new Date(UTCDateString);
    let day;

    if (dayDiff <= 1) {
      day = "today";
    } else if (dayDiff > 1 && dayDiff <= 2) {
      day = "yesterday";
    } else {
      day = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }

    // 显示不同时区的时间
    utcTime = dayjs.utc(date).local().format(`[${day}] HH:mm`);
  }
  if (type === "UTC") {
    // 传入UTC则将时间转换为UTC时间
    // 判断今天还是昨天
    let dayDiff = 3;
    const today = dayjs();
    const yesterday = dayjs().subtract(1, "day");
    if (dayjs(UTCDateString).isSame(today, "day")) {
      dayDiff = 1;
    } else if (dayjs(UTCDateString).isSame(yesterday, "day")) {
      dayDiff = 2;
    }
    const date = new Date(UTCDateString);
    let day;

    if (dayDiff <= 1) {
      day = "today";
    } else if (dayDiff > 1 && dayDiff <= 2) {
      day = "yesterday";
    } else {
      day = `${date.getMonth() + 1}.${date.getDate()}`;
    }

    // 显示不同时区的时间
    utcTime = dayjs(date).utc().format(`[${day}]_HH:mm`);
  }

  return utcTime;
}
