<template>
  <div class="relative mr-6 notification-box" @click="openNotification">

    <div class="icon_wrap">
      <img :src="notification_icon" class="notification-icon" alt="">
      <img :src="red_point" class="red-point" alt="" v-if="showRedPoint">
    </div>

    <div class="notification-content"
      :style="{ height: screenHeight * 0.63 + 'px', display: showNotification ? 'block' : 'none' }">
      <div class="notification-header">
        <div class="header-title">Notifications</div>
        <div class="service-icon">
          <div class="img-wrapper">
            <img :src="customer_service_icon" alt="">
          </div>

          <div class="service-tooltip-content">
            <p>Email us if you have any questions and we will get back to you shortly.</p>
            <p>Our Email: <a href=" mailto:support@boolvector.com" style="color:#A98CFF;">support@boolvector.com</a>
            </p>
          </div>
        </div>


      </div>
      <!-- 空表展示 -->
      <div class="empty-menu" :style="{ height: (screenHeight * 0.63 - 48) + 'px' }"
        v-show="!loading && notificationList.length === 0">
        <div class="empty-content">
          <img :src="empty_notice" class="empty-notice" alt="">
          <p>You don’t have any notification</p>
        </div>
      </div>
      <!-- 消息展示 -->
      <div class="notification-menu" :style="{ height: (screenHeight * 0.63 - 48) + 'px' }"
        v-show="notificationList.length > 0 || loading">
        <div v-for="item in notificationList" :key="item.time">
          <a-skeleton active style="padding-left:23px; padding-right: 23px;" :loading="loading">
            <notification-item :type="item.type" :time="item.time" :imgUrl="item.imgUrl" :disabled="item.disabled"
              :downloadUrl="item.downloadUrl" :text="item.text" />
          </a-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import notification_icon from "@/assets/notification_icon.svg"
import customer_service_icon from "@/assets/customer_service_icon.svg"
import empty_notice from "@/assets/empty_notice.svg"
import red_point from "@/assets/redpoint.svg"
import NotificationItem from '@/components/notification/notification-item.vue'
import { onMounted, ref, onUnmounted, reactive, watchEffect, watch } from "vue";
import { conversionUtcDate } from "@/utils/conversionTime";
import { getNotification } from "@/api/userInfo";
import { useUserStore } from "@/store";
import { storeToRefs } from 'pinia';
import messageWorker from '@/workers/message-worker.js'
import { eventTracking } from "@/utils/eventTracking.js"
const showRedPoint = ref(false);
const user = useUserStore();
const { showNotification, updateNotification } = storeToRefs(user);
const screenHeight = ref(0);
const menuDom = ref(null);
const listTotal = ref(20);
const loading = ref(false);
const notificationList = reactive([]);
const currentPage = ref(1);
// 新建worker
const workerInstance = new Worker(messageWorker);
workerInstance.onmessage = () => {
  // 在 worker通知后执行函数
  currentPage.value = 1;
  notificationList.splice(0, notificationList.length)
  updateList();
};


const initList = [
  {
    type: 'text',
    time: '12:50',
    imgUrl: 'unknown.com',
  },
  {
    type: 'image',
    time: '12:50',
    imgUrl: 'unknown.com',
    downloadUrl: 'unknown.com',
    disabled: true,
  },
  {
    type: 'image',
    time: '12:50',
    imgUrl: 'unknown.com',
  },
  {
    type: 'image',
    time: '12:50',
    imgUrl: 'unknown.com',
  },

]
// 其他组件通知更新列表
watch(updateNotification, () => {
  currentPage.value = 1;
  notificationList.splice(0, notificationList.length)
  updateList();
})

// 通知打开或关闭消息中心
watch(showNotification, (showNotification, pre) => {
  const fetchList = async () => {
    loading.value = true;
    currentPage.value = 1;
    initList.forEach(item => {
      notificationList.push(item)
    })
    showRedPoint.value = false;
    await updateList();
    localStorage.setItem('lastClickNotice', notificationList[0]?.time || 0);
    loading.value = false;
  }
  if (showNotification) {
    fetchList();
  }

})
watchEffect(() => {
  if (!loading.value && localStorage.getItem('lastClickNotice') < notificationList[0]?.time) {
    showRedPoint.value = true;
  }
})
const openNotification = () => {
  if (!showNotification.value) {
    eventTracking('booltool_notification_click', { click: 'check_notification' });
  }
  showNotification.value = !showNotification.value;

}
const closeNotificaition = () => {
  showNotification.value = false;
}
const updateScreenHeight = () => {
  screenHeight.value = window.innerHeight;
}
//滚动到列表底部更新列表
const updateList = async () => {
  await getNotification({ page: currentPage.value, size: 10 }).then(res => {
    if (loading.value) {
      notificationList.splice(0, notificationList.length);
    }
    const { data, code } = res;
    if (code === 0) {
      const { total, records } = data;
      records.forEach(item => {
        if (item.type === 'video_hd') {
          notificationList.push(
            {
              type: 'image',
              time: item.notifyTime,
              disabled: item.body.taskState === 2,
              downloadUrl: item.body.downloadUrl,
              imgUrl: item.body.previewImage,
              text: item.body.text,
            }
          );
        }
        if (item.type === 'text') {
          notificationList.push(
            {
              type: 'text',
              time: item.notifyTime,
              text: item.body.text
            }
          );
        }

      })
      listTotal.value = total;
      currentPage.value++;
    }
  });
  console.log(notificationList, '[update]');
}
onMounted(async () => {
  loading.value = true;
  updateScreenHeight();
  window.addEventListener('resize', updateScreenHeight);
  initList.forEach(item => {
    notificationList.push(item)
  })
  await updateList();

  document.addEventListener('click', (e) => {
    const contentDom = document.getElementsByClassName("notification-box")[0];
    if (!contentDom.contains(e.target) && showNotification.value) {
      closeNotificaition();
    }
  })
  if (notificationList.length > 0) {
    menuDom.value = document.getElementsByClassName("notification-menu")[0];

    // 滑动到了底部请求接口然后更新
    menuDom.value.addEventListener('scroll', function (e) {
      const isBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (isBottom && notificationList.length < listTotal.value) {
        updateList();
      }

    });
  }
  loading.value = false;
});
onUnmounted(() => {
  window.removeEventListener('resize', updateScreenHeight);
  document.removeEventListener('click', (e) => {
    const contentDom = document.getElementsByClassName("notification-box")[0];
    if (!contentDom.contains(e.target) && showNotification.value) {
      closeNotificaition();
    }
  })
  menuDom.value?.removeEventListener('scroll', async function (e) {
    const isBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (isBottom && notificationList.length < listTotal.value) {
      updateList();
    }
  });
});

</script>

<style lang="less" scoped>
.notification-box {
  display: inline-block;

}

.notification-box:hover .notification-content {
  // display: block;
}

.icon_outer {}

.icon_wrap {
  position: relative;
  padding: 5px;

  &:hover {
    background: #EDEDED;
    border-radius: 4px;
  }

}

.notification-icon {
  cursor: pointer;

}

.red-point {
  position: absolute;
  right: -2px;
  top: 6px;
}

.notification-content {
  width: 402px;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  border: 1px solid #F1F1F1;
  top: 45px;
  right: -48px;
  display: none;



  .notification-menu {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
      background-color: none;
      border-radius: 3.5px;
    }

    /*定义滚动条高宽及背景*/
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    /*定义滚动条*/
    &::-webkit-scrollbar-thumb {
      height: 7px;
      background: rgba(31, 35, 41, 0.3);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-corner {
      background-color: #EDEDED;
    }
  }

  .empty-menu {
    display: flex;
    flex-direction: column;

    .empty-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      justify-content: center;

      .empty-notice {
        width: 175px;
        margin-bottom: 16px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8F959E;
      }
    }
  }
}


.notification-content:before {
  content: "";
  position: absolute;
  top: -10px;
  right: 48px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #F1F1F1;
}

.notification-content:after {
  content: "";
  position: absolute;
  top: -9px;
  right: 49px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid #E9E9E9;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  color: #060606;



  .service-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;

    .img-wrapper {
      padding: 3px;
      cursor: pointer;

      &:hover {
        background: #EDEDED;
        border-radius: 4px;
      }

      img {
        width: 18px;

      }
    }


    &:hover {


      .service-tooltip-content {
        display: block;
      }
    }
  }


}



.service-tooltip-content {
  display: none;
  position: absolute;
  z-index: 9999;
  top: 51px;
  right: -26px;
  text-align: start;
  width: 327px;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 6px;
  padding: 20px 24px 24px 24px;
}

.service-tooltip-content::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 28px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.9);
}

:global(.service-tooltip) {
  max-width: 327px !important;
  width: 327px !important;
  height: 110px !important;
}

:global(.service-tooltip .ant-tooltip-inner) {
  width: 100% !important;
  height: 110px !important;
  padding: 0 !important;
  max-width: 100% !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

:global(.service-tooltip .ant-tooltip-content) {
  width: 100% !important;
  height: 110px !important;
  max-width: 100% !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
</style>