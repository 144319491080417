<template>
  <div class="layout font-hel">
    <div id="banner">
    </div>
    <a-layout>
      <div class="opacity-mask" v-if="showGlobalMask"> </div>
      <transition name="fade">
        <div :class="{ 'header-z-index': !isCollapse, 'side-btn-position': !isCollapse }">
          <Side ref="sideDom" />
        </div>
      </transition>
      <Header></Header>
      <div class="content-container">
        <a-layout-content>
          <router-view></router-view>
        </a-layout-content>
      </div>
      <!-- <Footer></Footer> -->
    </a-layout>
  </div>
</template>
<script setup>
import Header from "./components/header";
import Side from "./components/sider/index.vue";
import { onMounted, onUnmounted, ref, nextTick } from 'vue'
import { storeToRefs } from 'pinia'
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import { isLogin } from "@/utils/auth.js";
import { useSideStore, useUserStore, useSubscriptionInfo } from '@/store'
import { eventTracking } from '@/utils/eventTracking.js'

const appId = process.env.VUE_APP_INTERCOM_APP_ID
const intercomBaseApi = 'https://api-iam.intercom.io'
const excludePathnames = ['login', 'signup', 'resetPwd'];

const user = useUserStore();
const subscriptionInfo = useSubscriptionInfo();
const sideStore = useSideStore()
const { isCollapse, showGlobalMask } = storeToRefs(sideStore)

const route = useRoute();
const sideDom = ref(null);
const minHeight = ref(true)
const currentTab = ref("ToolkitHeader");
const mutationObserver = ref(null)

// 判断当前屏幕高度，做一些布局调整
const measureClientHeight = () => {
  return document.documentElement.clientHeight
}

const setMinHeight = () => {
  const clientHeight = measureClientHeight()
  if (clientHeight >= 900) {
    minHeight.value = false
    sideStore.setCurrentClient(false)
    sideStore.setCurrentClientHeight(clientHeight)
  } else {
    minHeight.value = true
    sideStore.setCurrentClient(true)
    sideStore.setCurrentClientHeight(clientHeight)
  }
}

const loadIntercomScript = (onload) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.src = 'https://widget.intercom.io/widget/' + appId
  const x = document.getElementsByTagName('script')[0]
  x.parentNode.insertBefore(script, x)
  script.onload = onload;
}

const setupIntercom = () => {
  window.intercomSettings = {
    app_id: appId,
    api_base: intercomBaseApi,
  }
  if (typeof window.Intercom === 'function') {
    window.Intercom('reattach_activator')
    window.Intercom('update', window.intercomSettings)
  } else {
    const Intercom = (...args) => Intercom.c(args)
    Intercom.q = []
    Intercom.c = args => Intercom.q.push(args)
    window.Intercom = Intercom

    const load = () => loadIntercomScript(startIntercom);
    
    if (document.readyState === 'complete') {
      load()
    } else if (window.attachEvent) {
      window.attachEvent('onload', load)
    } else {
      window.addEventListener('load', load, false)
    }
  }
}

const startIntercom = () => {
  window.Intercom('boot', {
    ...window.intercomSettings,
    user_id: user.email,
    email: user.email,
    package_code: subscriptionInfo.packageCode,
    hide_default_launcher: excludePathnames.includes(route.name),
  })
}

const showIntercom = (isHide) => {
  if (window.Intercom) {
    window.Intercom('update', { hide_default_launcher: isHide })
  }
}

onBeforeRouteUpdate((newRoute) => {
  const isHide = excludePathnames.includes(newRoute.name)
  showIntercom(isHide)
})

const handleIframeDomIsVisible = (isVisible) => {
  localStorage.setItem('announcementBoard', isVisible ? true : false)
}

const observeDom = async () => {
  await nextTick()
  handleIframeDomIsVisible(false)
  var callback = function () {
    const iframeDom = document.getElementsByTagName('iframe')[3]?.contentDocument
    const subDom = iframeDom?.getElementsByClassName('intercom-block-paragraph')[0]
    handleIframeDomIsVisible(subDom)
  };

  var observer = new MutationObserver(callback)
  var options = {
    attributes: true,
    childList: true,
    subtree: true,
  };
  observer.observe(document.body, options);
}

onMounted(setupIntercom);

onMounted(async () => {
  setMinHeight()
  window.onresize = () => {
    setMinHeight()
  }
  window.addEventListener("onresize", setMinHeight);
  if (isLogin.value) {
    // await user.userSubscription();
    await subscriptionInfo.refresh();
  }
  observeDom()
  eventTrackingFn()
});

const eventTrackingFn = () => {
  // 进入页面就上报
  if (route.query?.utm_source) {
    sessionStorage.setItem("utm_source", route.query?.utm_source)
  } else {
    sessionStorage.removeItem("utm_source")
  }
  if (route.query?.ref) {
    sessionStorage.setItem("ref", route.query?.ref)
  } else {
    sessionStorage.removeItem("ref")
  }
  // eventTracking("booltool_homepage_view")
}

onUnmounted(() => {
  window.removeEventListener("click", sideDom.value.closeModal);
});
</script>
<style lang="less" scoped>
.layout {
  background-color: #fff;
  // min-width: 1280px;
  height: 100%;
  position: relative;


  .ant-layout {
    height: 100%;
    background-color: #fff;
  }

  .content-container {
    min-height: calc(100vh - 60px);
    position: relative;
  }

  :global(.ant-layout-content) {
    background-color: #fff;
    min-height: calc(100vh - 60px);
    height: 100%;
  }

  .side-btn-position {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;


  }

  .fade-enter-from,
  .fade-leave-to {
    /*定义进入开始和离开结束的透明度为0*/
    opacity: 0;
  }

  .fade-enter-to,
  .fade-leave-from {
    /*定义进入结束和离开开始的透明度为1*/
    opacity: 1;
  }

  .fade-enter-active,
  .fade-leave-active {
    /*定义进入和离开过渡状态的透明度变化的时间和变化曲线*/
    transition: opacity 0.5s ease;
  }

  img {
    -webkit-user-drag: none;
  }
}

.layout-prevent {
  height: calc(100vh - 60px);
  overflow: hidden;
}

.header-z-index {
  z-index: 999;
}

.opacity-mask {
  width: 100%;
  height: calc(100vh);
  background-color: #fff;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  cursor: not-allowed;
}
</style>
