<template>
  <div>
    <button @click="handleSignClick" class="sign-button">Sign up</button>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
const handleSignClick = () => {
  router.push({
    name: "signup",
    query: router.currentRoute.value.query 
  })
}
</script>

<style lang="less" scoped>
.sign-button {
  width: 84px;
  height: 36px;
  border: 1px solid #FFFFFF;
  border-radius: 60px;

  transition: all 0.5s;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
}
</style>