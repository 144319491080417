<template>
  <div>
    <div @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" class="user-info-container">
      <img :src="userAvatar" class="rounded-full cursor-pointer" />
      <div class="menu-list-hover-wrap" v-if="showUserInfo">
        <ul class="menu-list-container">
          <li class=" flex menu-item">
            <div class="font-semibold username">{{ userName }}</div>
            <div class="pro" v-if="planName !== 'Free'">{{ planName }}</div>
          </li>
          <li class="menu-item cursor-item ">
            <div @click="handleResetPwd">Reset Password</div>
          </li>
          <li class="menu-item cursor-item" @click="handleLogout">
            <div>Log out</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import userAvatar from "@/assets/user_default_avatar.svg";
import { useUserStore, useSubscriptionInfo } from '@/store'
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
const userStore = useUserStore()
const subscriptionInfo = useSubscriptionInfo()
const { userName } = storeToRefs(userStore)
const router = useRouter()
const showUserInfo = ref(false)
const planName = ref('Free')
//重置密码
const handleResetPwd = () => {
  router.push({ path: 'resetPwd', query: { step: 1, type: "reset" } })
}

//退出登录
const handleLogout = async () => {
  router.push('/login')
  await userStore.logout()
}

const handleMouseEnter = () => {
  showUserInfo.value = true
}

const handleMouseLeave = () => {
  showUserInfo.value = false
}

watchEffect(() => {
  switch (subscriptionInfo.packageCode) {
    case 201101:
    case 201102:
    case 200801:
      planName.value = 'Pro';
      break;
    case 201201:
    case 201202:
    case 200901:
      planName.value = 'Ultra';
      break;
    default:
      planName.value = 'Free';
      break;
  }
});
</script>

<style lang="less" scoped>
.header-dropdown {
  .ant-dropdown-menu {
    box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.08);
    border-radius: 4px;
  }
}

.username {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 107px;
}

.pro {
  height: 20px;
  padding: 0 6px;
  align-items: center;
  background: #060606;
  border-radius: 2px;
  color: #FFE68E;
  line-height: 20px;
  margin-left: 8px;
  text-align: center;
  font-size: 12px;
}


.position-container {
  position: relative;
}

.menu-list-hover-wrap {
  position: absolute;
  top: 35px;
  right: 0px;
  z-index: 999;
}

.menu-list-container {
  margin-top: 24px;
  border: 1px solid #DEE0E3;
  box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.08);
  border-radius: 4px;
  padding: 8px 0px;
  min-width: 170px;
  border-radius: '4px';
  background-color: #fff;

  .menu-item {
    height: 32px;
    line-height: 32px;
    color: #1F2329;
    padding: 0 12px;
    display: flex;
    align-items: center;


    &:hover {
      background: #EFF0F1;
    }
  }

  .cursor-item {
    cursor: pointer;
  }
}
</style>